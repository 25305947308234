
import { defineComponent, ref } from 'vue'
import usePackageDetail from "@/hooks/checking/spotChecking/checkingPreparation/usePackageDetail";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
type Key = ColumnProps['key'];
// const tableWidth = window.innerWidth
const tableHeight = window.innerHeight-420
import TableWrapper from "@/components/TableWrapper.vue";
import { useStore } from "vuex";
import CommentModal from './commentModal.vue';
import GetColumnWidth from '@/utils/payment/getColumnWidth'
export default defineComponent({
  name: 'payoutPreparation',
  components: {
    // Pagination,
    TableWrapper,
    CommentModal
  },
  props: {},
  setup() {
    const store = useStore()
    const {
      searchParams, doReset,
      columnsData, tableData, doSearch, pageChange, sizeChange, pageSize, pageNumber, total,
      payoutRoundClick, commentVisible, commentData, commentClick, handleDownloadSourceData
    } = usePackageDetail()
    const handlePageChange = (page: number,pageSize: number) =>{
      pageChange(page, pageSize)
    }
    const handleSizeChange = (page: number,pageSize: number) =>{
      sizeChange(page, pageSize)
    }
    const tableWidth = ref(GetColumnWidth(columnsData))
    return {
      store,
      searchParams,
      doSearch,
      doReset,
      tableWidth,
      tableHeight,
      columnsData,
      tableData,
      pageSize,
      pageNumber,
      total,
      handlePageChange,
      handleSizeChange,
      payoutRoundClick,
      commentVisible,
      commentData,
      commentClick,
      handleDownloadSourceData
    }
  }
})
